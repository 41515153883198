import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Select",
  "type": "Form",
  "icon": "arrowDownSquareLine"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Select`}</strong>{` is used as a replacement of the native select element. It is used for
collecting user provided information from a list of options.`}</p>
    <hr></hr>
    <h2>{`Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true",
        "live": "true",
        "center": "true"
      }}>{`() => {
  const [value, setValue] = React.useState(null);

  return (
    <div style={{ width: "200px" }}>
      <Select
        onChange={setValue}
        value={value}
        placeholder="Choose document"
        label="Document"
        options={[
          { id: "1", label: "First" },
          { id: "2", label: "Second" },
          { id: "3", label: "Third" },
          { id: "4", label: "Fourth" },
          { id: "5", label: "Fifth" },
          { id: "6", label: "Sixth" },
        ]}
      >
        Some content
      </Select>
    </div>
  );
};
`}</code></pre>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`Select`}</strong>{` accepts an `}<inlineCode parentName="p">{`options`}</inlineCode>{` prop which define an option that is available to
be selected by the user. The options can have an `}<inlineCode parentName="p">{`id`}</inlineCode>{` and a `}<inlineCode parentName="p">{`label`}</inlineCode>{` properties, which
correspond to the `}<inlineCode parentName="p">{`value`}</inlineCode>{` prop, and the label that will be rendered to the user.`}</p>
    <h2>{`Interaction`}</h2>
    <p><strong parentName="p">{`Select`}</strong>{` is an uncontrolled component by default. Use the `}<inlineCode parentName="p">{`onChange`}</inlineCode>{`, `}<inlineCode parentName="p">{`value`}</inlineCode>{` props
to define the user interaction behavior for the component.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The `}<em parentName="p">{`value`}</em>{` prop accepts only a value that has been defined in the `}<em parentName="p">{`options`}</em>{` prop.`}</p>
    </blockquote>
    <h2>{`States`}</h2>
    <h3>{`Disabled`}</h3>
    <p><strong parentName="p">{`Select`}</strong>{` can also be disabled with a prop`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true",
        "live": "true",
        "center": "true"
      }}>{`<div style={{ width: "200px" }}>
  <Select
    value={"1"}
    disabled
    stretch
    options={[{ id: "1", label: "John Doe" }]}
  />
</div>
`}</code></pre>
    <h2>{`Label`}</h2>
    <p><strong parentName="p">{`Select`}</strong>{` accepts a `}<inlineCode parentName="p">{`label`}</inlineCode>{` prop as well, which will render the select with an
appropriate label`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true",
        "live": "true",
        "center": "true"
      }}>{`<div style={{ width: "200px" }}>
  <Select
    value={"1"}
    label="Name"
    helper="First and last"
    options={[
      { id: "1", label: "John Doe" },
      { id: "2", label: "Doe Johnson" },
    ]}
  />
</div>
`}</code></pre>
    <h2>{`Props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`label`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Label rendered with the select field`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`helper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Message rendered on the right of the label ( with `}<em parentName="td">{`label`}</em>{` prop only )`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`disabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Disallows any interaction of the select`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`options`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Array<{ id: string, label: string }>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The options available to the user`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onChange`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`( value : string ) => void`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Callback when an option has been selected`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      